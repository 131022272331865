// @import "public/assets/components/bootstrap-material-design/scss/bootstrap-material-design.scss";
// @import "bootstrap";
// @import "../public/assets/components/bootstrap-sass/assets/stylesheets/bootstrap";
// @import "public/assets/components/bootstrap-material-design/sass/bootstrap-material-design";
// @import "../vendor/bootstrap-material-design/sass/ripples";
// working one 
// @import "public/assets/components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// @import "public/assets/components/bootstrap-material-design/sass/bootstrap-material-design.scss";
// @import "public/assets/components/bootstrap-material-design/sass/ripples.scss";

// @import "public/assets/components/bootswatch-scss/paper/variables";
@import "resources/assets/sass/paper/variables";
@import "public/assets/components/bootstrap-sass/assets/stylesheets/bootstrap";
@import "resources/assets/sass/paper/bootswatch";

// sweet alerts
@import "public/assets/components/sweetalert/dev/sweetalert.scss";
// animate css
@import "/assets/components/animate.css/animate.min.css";

@import "resources/assets/sass/footer";
@import "resources/assets/sass/starrating";
@import "resources/assets/sass/teampick_brand";
@import "resources/assets/sass/landing";




