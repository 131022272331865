.landing 
{

	background: url('/img/background.jpg');
	background-size: cover;
	height: 100vh;
	width: 100vw;
	display:table;

	.navbar-inverse { background: transparent; box-shadow:none; padding-top:30px; }
	.vertical-center { 
		text-align: left;		
		h1 { color: #fff; }
		p { color: rgba(255,255,255,.75); }
		.polaroid { 
			max-width: 500px; 
			margin-top: 40px;
			width:100%;
		}

		@media (min-width: $screen-lg-min) 
		{
			h1 { font-size:60px; line-height:72px; }
			p { 
				margin-top: 40px;
			    margin-bottom: 40px;
			    font-size: 21px;
			    line-height: 30px;
			    max-width:500px;
			}

		}
		
	}


	@media (min-width: $screen-lg-min) 
	{

		.btn-mg{
		    padding: .8em 3.5em !important;
		}
	}

	footer { 
		background: rgba(0,0,0, 0.3);
		color: #AAA;		
		a { color: #AAA;  }
	}
}