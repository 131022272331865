$logo-font-family:  "Oregano", "Helvetica Neue", Helvetica, Arial, sans-serif !default;

.error { font-weight: 500; color: red; }
.panel { margin-bottom:50px;} 
.fade-transition { transition: all .4s ease; visibility: visible; opacity: 1; }
.fade-enter { opacity: 0;  visibility: hidden; height: 0; }
.fade-leave  { display: none; }


table tr td.half { width: 50%;  }
table tr td.quarter { width: 25%;  }
table tr td.pad-20 { padding: 20px 5px 20px 5px; }
table tr:first-child td { border:0 !important; }

.grid-picklist-items { position: relative; width: 100%;}

@mixin full-word-break() {
  -ms-word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.thumbnail {


  @include full-word-break();

  display: inline-block;
  width: 100%;


  .star-rating { position: absolute; bottom: 35px; }
  .img-area {


    transition: background 0.5s;
    -webkit-transition: background 0.5s;
    position: relative;

    .right-corner
    {
        position:absolute; right:10px; top: 10px; z-index: 10;

        .btn{  opacity: 0.5; }

    }

    &:hover .btn{ opacity: 1.0; }


    .descript { position: absolute; opacity: 0; bottom: 5px; max-height: 115px; overflow: auto;  line-height: 15px; font-size: 13px;  color: #fff; padding: 10px; }
    .descript a { color: #ffffff; }
    &:hover { background: #333;  }

    &:hover img { opacity: 0.7;}
    &:hover .descript { opacity: 1; }

  }

  small { line-height: 15px; font-size: 10px; }



  @media (min-width: $screen-md-min)
  {
    padding-bottom: 40px;
  }

  @media (max-width: $screen-md-min)
  {
    .img-responsive { width: 100%; margin-bottom: 50px;}
    .star-rating { bottom: 40px;}
  }

}

.navbar-brand {  font-family: $logo-font-family;  font-size:32px;    }
.panel-heading {  font-size:18px;   }

.contentoverlay {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9000;
  background-color: rgba(255, 255, 255, 0.95);
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  overflow: hidden;
  -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  visibility: hidden;
  opacity: 0;

  &.active{

    opacity: 1;
    visibility: visible;
    transition: clip-path 0.5s;
    -webkit-transition: -webkit-clip-path 0.5s;
    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 28%, 100% 65%, 100% 100%, 49% 100%, 0 100%, 0 68%, 0 29%, 0 0);
    clip-path: polygon(50% 0%, 100% 0, 100% 28%, 100% 65%, 100% 100%, 49% 100%, 0 100%, 0 68%, 0 29%, 0 0);
  }

  .close-btn {
    position: absolute;
    right: 30px;
    top: 10px;
    font-size: 20px;
  }

  .overlay-content {
    height: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling:touch;

    .detail {
      margin: 0 auto;
      max-width: 80vw;
      display: inline-block;
    }

    .img-responsive { margin: 0 auto;}

  }

}

.showing-overlay {
  height: 100vh;
  overflow: hidden;
  position: absolute;
  top:0;
}

.full-word-break { @include full-word-break(); }